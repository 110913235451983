$prefix: App;

$logo: #ec1d8f;
$logoBg: #373535;



$bg: #1f1500;
$txt: #fff;
$visited: #a3055c;

$padding: 15px;
$border: 1px solid $txt;

@mixin link {
  color: $logo;
  text-decoration: none;
  &:hover {
    color: $txt;
  }
}

body {
  font-size: 14px;
  background-color: $bg;
  background: linear-gradient($logoBg,$bg);
  background-attachment: fixed;
  line-height: 1;
}

h1, h2, h3, p {
  margin: $padding 0;
}
h1 {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: capitalize;
}
h2 {
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  &::before, &::after {
    content: '*';
    margin: $padding/2;
  }
}
p, ul {
  line-height: 1.2;
}

a, button, input {
  &:focus {
    outline: none;
  }
  
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: $logoBg; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: $logo; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: $visited; 
}

.#{$prefix} {
  padding: $padding;
  // background-color: $bg;
  // background: linear-gradient($logoBg,$bg);
  // background-attachment: fixed;

  color: $txt;
  text-align: center;

  max-width: 1140px;
  margin: 0 auto;
}
.#{$prefix}-section {
  padding: $padding;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
}
.#{$prefix}-header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  padding: 0;
  width: 100%;
}

.#{$prefix}-logo {
  height: 30px;
  &:hover {
    animation: #{$prefix}-logo-spin 1s linear 0s infinite;
  }
}

.#{$prefix}-nav {
  font-size: 1rem;
  line-height: 30px; // match logo
  background-color: rgba(255,255,255,0.05);
  
  &__ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      list-style-type: none;
      text-align: left;
      flex: 0 1 auto;
      position: relative;
      &:last-child {
        .#{$prefix}-nav__ul {
          right: 0;
        }
      }
      &:hover {
        .#{$prefix}-nav__ul {
          display: inline-block;
        }
      }

      .#{$prefix}-nav__link {
        padding: $padding;
        display: flex;
        position: relative;
        //font-size: 1rem;
        @include link;
        &--dropdown {
          &::after {
            content: '';
            width: 6px;
            height: 6px;
            margin: 9px 5px 5px 5px;
            border: solid $logo;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
          }
          & + .#{$prefix}-nav__ul li .#{$prefix}-nav__link.active {
            background-color: $visited;
          }
        }
        &--home:not(.active)  {
            &::after {
              content: 'Home';
              display: inline-block;
              margin-left: $padding/2;
            }
          
        }
      }

      .#{$prefix}-nav__ul {
        width: 200px;
        position: absolute;
        z-index: 100;
        display: none;
        font-size: 0.7rem;
        li {
          float: left;
          width: 100%;
          .#{$prefix}-nav__link {
            display: block;
            color: $txt;
            background-color: $bg;
            border-top: 1px solid $txt;
            &:hover {
              color: $logo;
            }
          }
        }
      }
    }
  }
  &__primary {
    li {
      line-height: 30px;
    }
  }
  &__breadcrumb {
    font-size: 0.7rem;
    // border: 2px solid $logoBg;
    // border-width: 0 5px;
  }
  .active {
    color: $txt !important;
    font-weight: 700;
  }
}

.#{$prefix}-link {
  @include link;
  /* &:visited {
    color: $visited;
  } */
}
.#{$prefix}-btn {
  @include link;
  padding: $padding;
  margin: $padding;
  display: inline-block;
  background-color: rgba(255,255,255,0.1);
  border: 1px solid $logo;
  border-radius: 3px;
  font-weight: 700;
  color: $logo;
  text-transform: capitalize;
  &:hover {
    color: $txt;
  }
}
.#{$prefix}-img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  &--topleft {
    float: left;
    margin-right: 15px;
  }
  &--round {
    border-radius: 50%;
  }
}
.#{$prefix}-list {
  text-align: left;
  padding: 0 0 0 $padding*2;
  li {

  }
}

.#{$prefix}-content {
  min-height: 50vh;
  background-color: $logoBg;
  &:first-of-type {
    margin-top: 45px;
  }
  p {
    text-align: left;
    margin: $padding 0;
  }
  & + .#{$prefix}-content {
    margin-top: $padding;
  }
  &__home {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    margin: -$padding;
    
    &__h1 {
      font-size: 1.6rem;
      text-shadow: -3px 3px #000;
      opacity: 0.66;
      margin: $padding;
      // text-shadow: 1px 1px $txt, -1px -1px $bg;
      // color: $logoBg;
    }

    .#{$prefix}-logo {
      height: 20vmin;
    }
    p {
      margin: $padding;
    }
  }
  &--center {
    min-height: inherit;
    p {
      text-align: center;
    }
  }
}
.#{$prefix}-project {
  width: 100%;
  display: block;
  overflow: hidden;
  
  &__img, &__detail {
    box-sizing: border-box;
    margin: 0;
    padding: $padding;
    width: 50%;
    float: left;
    background-color: rgba(255,255,255,0.1);
    border: 5px solid $logoBg;
    border-width: 5px 0;
    height: 400px;
    color: $logo;
  }
  &__name {
    text-align: left;
  }
  &__des, &__year, &__status, &__url {
    &::before {
      text-transform: capitalize;
      font-weight: 700;
    }
  }
  &__des {
    &::before {
      content: 'description: ';
      display: block;
      margin-bottom: $padding/2;
    }
  }
  &__img {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    
    &::-webkit-scrollbar-track {
      background-color: $bg; 
    }
    img {
      width: 100%;
      height: auto;
    } 
  }
  &__year {
    &::before {
      content: 'year: ';
    }
  }
  &__status {
    &::before {
      content: 'status: ';
    }
  }
  &__url {
    &::before {
      content: 'url: ';
    }
  }
}
.#{$prefix}-footer {
  border-top: $border;
}

.#{$prefix}-arrow {
  
  &--down {
    display: flex;
    animation: #{$prefix}-arrow-down 1.5s ease-out 0.5s infinite;
    position: absolute;
    bottom: 30px;
    margin-bottom: $padding;
    &::before, &::after {
      background-color: $txt;
      content:'';
      position: relative;
      height: 5px;
      width: 20px;
    }
    &::before {
      transform: skew(0deg, 30deg);
      // left: 0;
    }
    &::after {
      transform: skew(0deg, -30deg);
      // right: 0; 
    }

    & + .#{$prefix}-arrow__label {
      font-size: 0.8rem;
      position: absolute;
      bottom: 60px;
    }
  }
}


@keyframes #{$prefix}-arrow-down {
  from {
    opacity: 1;
    
  }
  to {
    opacity: 0;
    transform: translateY(30px);
  }
}

@keyframes #{$prefix}-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 768px) {
  
  .#{$prefix} {
    padding: $padding 0;
  }
  .#{$prefix}-content__about {
    p {
      margin: $padding;
    }
    .#{$prefix}-list {
      padding: 0 0 0 40px;
    }
  }
  .#{$prefix}-img {
    &--topleft {
      margin-left: $padding/2;
    }
  }
  
  .#{$prefix}-section {
    padding: $padding 5px;
  }
  .#{$prefix}-header {
    padding: 0;
  }
  
}
@media only screen and (max-width: 480px) {
  .#{$prefix}-img {
    &--topleft {
      float: none;
      clear: both;
      margin: 0 auto;
    }
  }
  .#{$prefix}-project {
    &__img, &__detail {
      width: 100%;
      height: auto;
    }
      
    &__img {
      max-height: none;
      overflow-y: hidden;
      // border-width: 0 5px;
      background-color: transparent;
    }
  }
}
@media only screen and (max-width: 400px) {
  .#{$prefix}-header {
    width: 100%;
  }
  .#{$prefix}-nav {
    &__ul {
      justify-content: space-evenly;
      li {
        &:hover {
          .#{$prefix}-nav__ul {
            display: none;
          }
        }
        .#{$prefix}-nav__link {
          padding: $padding 5px;
          &--dropdown, &--home:not(.active) {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
  
}
@media only screen and (max-width: 250px) {
  .#{$prefix}-content {
    &:first-of-type {
      margin-top: 105px;
    }
  }
}